<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>


<v-row justify="center">
    <v-dialog
     v-model="dialog"
     scrollable
     max-width="600px"
   >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ form_title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
          <v-row>
            <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="businesses"
                  item-value=id
                  item-text=name
                  label="Compte *"
                  required
                  v-on:change="setBusinessStores(device.business_id)"
                  v-model=device.business_id
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="stores"
                  item-value=id
                  item-text=name
                  label="Magasin *"
                  required
                  v-model=device.store_id
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Appareil *"
                  required
                  v-model=device.name
                ></v-text-field>
                
              </v-col>
              <v-col cols="12" sm=6 md=6>
                <v-text-field
                  label="Fournisseur *"
                  required
                  v-model=device.manufacturer
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Modèle *"
                  required
                  v-model=device.model
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Numéro de Série"
                  required 
                  v-model=device.serial_number
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm=6 md=6>
                <v-text-field
                  label="Montant *"
                  required 
                  v-model=device.amount
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  :items="status"
                  item-value=id
                  item-text=name
                  label="Statut *"
                  required
                  v-model=device.status
                ></v-select>
              </v-col>
              <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="device.next_renewal_date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="device.next_renewal_date"
            label="Prochain Renouvellement"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="device.next_renewal_date"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Annuler
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(device.next_renewal_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
          </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="close_modal_button"
            text
            @click="close"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue"
            text
            class = "confirm_modal_button"
            v-on:click=save
            :loading="loading"
            :disabled="loading"
            @click="loader = 'loading'"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>
  <v-card>
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
  <template v-slot:item.amount="{ item }">
        {{ item.amount | formatNumber }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        :class="getStatusColor(item.status)"
      >
        {{ getStatusLabel(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.role.name="{ item }">
      <v-chip class=uppercase
      >
        {{ getRoleLabel(item) }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2 edit_button"
        @click="edit(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template slot="body.append">
      <tr>
          <th>TOTAL</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ total() | formatNumber }} HTG</th>
          <th></th>
          <th></th>
          <th></th>
      </tr>
  </template>
  </v-data-table>
</v-card>

</v-card>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'

export default {
  name: 'SettingsView',
  methods: {
    load: function(){
      var self = this;
      this.$axios.get('/devices?include=business,store')
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    getStatusColor: function(status){
        var color = "class_red"
        if(status){
          color = "class_green"
        }
        return color
      },
      getBusinesses: function(){
        var self = this;
        this.$axios.get('/businesses')
        .then(function (response) {
          self.businesses = response.data
        })
      },
      getStores: function(){
        var self = this;
        this.$axios.get('/stores')
        .then(function (response) {
          self.all_stores = response.data
        })
      },
      getStatusLabel: function(status){
        var label = "Annulé"
        if(status){
          label = "Actif"
        }
        return label
      },
      setBusinessStores:function(business_id){
        this.stores = []
        for (var i = this.all_stores.length - 1; i >= 0; i--) {
          if(this.all_stores[i].business_id == business_id){
            this.stores.push(this.all_stores[i])
          }
        }
      },
      getAmount: function(amount){
        return amount
      },
      save: function(){
        this.loading = true
        var self = this
        var url = ''
        if (this.index > -1) { 

          url = '/devices/'+self.device.id
          this.$axios.put(url+"?include=business,store&data="+encodeURIComponent(JSON.stringify(self.device)))
          .then(response => {
            Object.assign(self.rows[self.index], response.data)
            self.dialog = false
            self.loading = false
            self.reset()
          })
        }
      },
      edit: function(item){
        this.setBusinessStores(item.business_id)
        this.index = this.rows.indexOf(item)
        this.device = Object.assign({}, item)
        this.form_title = "Editer Souscription : "+this.device.name
        this.dialog = true
      },
      close: function(){
        this.dialog = false
        this.reset()
        this.loading = false
      },
      reset: function(){
        this.device = {
          name: '', 
          manufacturer: '', 
          model: '', 
          serial_number: '',
          status: 1,
          amount:60,
          next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
        }
        this.index = -1
        this.form_title = 'Nouvelle Souscription'
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.reset()
        })
      },
      total() {
        var total = 0
        for (var i = this.rows.length - 1; i >= 0; i--) {
          if(this.rows[i].status == 1){
            total = total + parseInt(this.rows[i]['amount'])
          }
        }
        return total
    }
  },
  mounted(){
    this.load()
    this.getBusinesses()
    this.getStores()
  },
  data: () => ({
      menu: false,
      modal: false,
      menu2: false,
      search: '',
      status: [{name: "Active", id: true}, {name: 'Blocked', id: false}],
      index: -1,
      loading: false,
      form_title: "Nouvelle Souscription",
      items: [
        {
          text: 'Souscriptions',
          disabled: false,
          href: '/subscriptions',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: 'NOM',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'COMPTE', value: 'business.name' },
          { text: 'MAGASIN', value: 'store.name' },
          { text: 'SN', value: 'serial_number' },
          { text: 'MONTANT', value: 'amount' },
          { text: 'PROCHAIN RENOUVELLEMENT', value: 'next_renewal_date' },
          { text: 'STATUT', value: 'status' },
          { text: 'ACTIONS', value: 'actions' },
        ],
        rows: [],
        businesses: [],
        all_stores: [],
        stores: [],
        dialog:'',
        dialogDelete: false,
        device: {
          name: '', 
          manufacturer: '', 
          model: '', 
          serial_number: '',
          status: 1,
          amount:60,
          next_renewal_date:(new Date(Date.now())).toISOString().substr(0, 10),
        }
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#F68D3A !important;
  }

  .settingscontainer{
    margin-top:25px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003f74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .new_button{
    margin-top:-7px!important;
  }

  .color-red{
    color:red!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }

.v-progress-circular{
    margin-top:40px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
</style>